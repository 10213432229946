import React, { Component } from 'react'
import styled from 'styled-components'

import {SiteConsumer} from '../context'

import { } from 'reactstrap';

export default class Footer extends Component {

    render() {
        return (
            <SiteConsumer>{
                (value)=>{ 
                    return (
                        <FootWrapper>
                            <div className="footerr">
                                <a className="white" href="https://www.linkedin.com/in/benjamin-thwaites-638620110/">
                                    <i className="fab fa-linkedin"  ></i>
                                </a>

                                <a className="white" href="https://github.com/BenskiBoy">
                                    <i className="fab fa-github"></i>
                                </a>
{/*
                                <a className="white" href="mailto:thwaitsy@gmail.com">
                                    <i className="fas fa-envelope"></i>
                                </a>
*/}
                            </div>
                        </FootWrapper>
                    )
                }   
            }
            </SiteConsumer>
        )
    }
}
const FootWrapper = styled.nav `
.footerr {
    font-size: 3rem;
    text-align: center;
    height: 5rem;
    
}

.white {
    color: white;
    padding-left: 1em;
    }
`