import React, { Component } from 'react'
import styled from 'styled-components'

import {SiteConsumer} from '../context'

import { Collapse, Navbar, NavbarToggler, Nav, NavItem, NavLink, Container } from 'reactstrap';

export default class NavbarSite extends Component {

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
    //console.log("toggled" + this.state.collapsed)
  }

  componentDidMount(){
    //console.log("Set in navbar mount: " + JSON.parse(localStorage.getItem('ProjectsExpanded')))
  }

  render() {
    return (
      <SiteConsumer>
        {(value)=>{ 
          return (
            <NavWrapper>
            <nav className="">
              <div className="sidebar-sticky">
                <ul className="nav ">

                <Navbar color="faded" light>
                  <Nav navbar>
                    <NavItem>
                      <NavLink href="home" >Home</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="about">About Me</NavLink>
                    </NavItem>
                    {/*
                    <NavItem>
                      <NavLink href="contact">Contact</NavLink>
                    </NavItem>
                    */}
                    <NavItem>
                      <NavLink href="BlogList">Blog</NavLink>
                    </NavItem>

                    <Container>
                        <NavbarToggler onClick={value.toggleProjectsExpanded} >
                          <NavItem >
                            
                          <i className="fa fa-angle-down" ></i>
                          <div>
                            &nbsp;&nbsp;Projects 
                          </div>
                          </NavItem>
                        </NavbarToggler>
                        
                      <Collapse isOpen={value.ProjectsExpanded}>
                        <Nav className="project-nav-link" navbar>
                          <NavItem>
                            <NavLink href="Temperature">Temperature</NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href="Z80">Z80</NavLink>
                          </NavItem>
          {/*
                          <NavItem>
                            <NavLink href="about">Project 2</NavLink>
                          </NavItem>
                          */}
                        </Nav>
                        </Collapse>
                      </Container>
                    </Nav>
                </Navbar>

                </ul>
              </div>
            </nav>
          </NavWrapper>
        )}}
      </SiteConsumer>
    )
  }
}
const NavWrapper = styled.nav `

.navbar-light .navbar-nav .nav-link{
  color: white
}

.navbar-light .navbar-nav .nav-link:hover{
  color: grey
}

.navbar-toggler {
  border: none;
  text-align: left !important;
  width: 100px;
  padding: 0.5rem 100% 1rem 0rem;

  font-weight: 500;
  font-size: 1rem;
  outline:none;
  color: white;

}

.navbar-light .navbar-toggler {
  color: white;
}

.navbar-light .navbar-toggler:hover {
  color: grey;
}

.button {
  width: inherit;
}

.fa {
  float: left;
}

.navbar-toggler:hover{
  color: inherit;
}

.container {
  text-align: left;
  padding-right: 0;
  padding-left: 0;
  display: block;
  width:fit-content;
}

.project-nav-link{
  padding-left: 1.5rem;
}

body {
  font-size: .875rem;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
* Sidebar
*/

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.nav-link {
  fontsize: 4vw;
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;

}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
  
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

/*
* Content
*/

[role="main"] {
  padding-top: 133px; /* Space for fixed navbar */
}

@media (min-width: 768px) {
  [role="main"] {
    padding-top: 48px; /* Space for fixed navbar */
  }
}

/*
* Navbar
*/

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
  
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}


`