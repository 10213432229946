import React, { Component } from 'react'
import styled from 'styled-components'

export default class Temperature extends Component {

  /*
  componentDidMount() {
    console.log('mounting blog')
    fetch('https://ane6ue9h03.execute-api.ap-southeast-2.amazonaws.com/dev/temperature', { 
      method: 'get', 
      headers: {
        'x-api-key':'r2HDf5R9Mh6AAs7gWeajB4s2sKtfHE0G4fl4pmX9',
        'start_time':'1',
        'end_time':'1556252520',
      },
      mode: 'cors'
    }).then(function (response){
      response.text().then(function(data) {
        console.log(JSON.parse(data)[0]['temperature']);
      });
    }).catch(error => this.setState({
      isLoading: false,
      message: 'Something bad happened ' + error
    }))
  }
  */

  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    return (
      <div>
        <TemperatureWrapper>
        <div className="TemperatureWrapper">
          <h2>Temperature Monitoring</h2>
            <h3>Background</h3>
            <div className="text">
              I live in a bit of an older house, from the styling of the bathroom and laundry I'd guess it was built in the 40's - 50's. So with little insulation and paper thin windows and doors with large gaps around the frame, it gets pretty chilly during winter despite being in Australia.
              <br/><br/>
              With winter just around the corner I wanted to monitor how cold my bedroom gets so I came up with this.
            </div>

            <br/><br/>

            <h3>Overview</h3>
            <div className="text">
              Three things that get me all excited and frothing at the mouth are raspberry pi's, nifty sensors and API's. 
              I've used a Raspberry Pi Zero along with a DHT11 temperature/humidity sensor to retrieve and forward everything to an AWS REST API to achieve maximum mouth froth.
              <br/><br/>
              <img alt="pi_zero" className="img" src={require("../images/pi_zero.png")} height="300px" />
              <br/><br/>
              The Pi is running a really simple python script which you can find <a href="https://github.com/BenskiBoy/RPiTemperatureMonitoring/blob/master/temp_logger.py">here</a>.
              <br/><br/>
              I'm also passing the static ip address, hostname and gateway of the Pi incase I want to add more sensor devices and need to split them up in the queries.
              <br/><br/>
              The python script passes the temperature and humidity to a AWS Lambda function through AWS API Gateway. This lambda function then inserts the values into a MySQL DB making it nice and easy for Splunk to ingest.
              <br/><br/> 
              Within Splunk i made up a scheduled report and used the <a href="https://docs.splunk.com/Documentation/Splunk/7.2.6/Report/Embedscheduledreports">embed</a> feature, the results of which you can see below.
              <br/><br/>
              <iframe title="splunk_embed" height="340" width= {this.state.width * 0.6} frameBorder="0" src="https://splunk.benthwaites.net/en-GB/embed?s=%2FservicesNS%2Fbennyboyadmin%2Fsearch%2Fsaved%2Fsearches%2FTemperature&oid=5KGTluz3H%5EHVa7ilP0NdCXrgy_FElolbgafr3kxvQ9ZgmEDjST2zwU5oTZZVTuIKJxyHhU_mFiqaI_0GSehDW6nk7aiFK62xtNqG_M73AfpwE8AK6HLH%5E_bIQDO4yi1rhnKn2lWq%5EvqItkwfSrI0JCfQzQoyuN"></iframe>
              <br/><br/>
              I also added a query method to the REST API which I'll eventually use to create a little d3 chart at some point.
              <br/><br/>
              In a week or two I'll add some diagrams and perhaps some code on how the AWS side of things works so STAY TUNED!
              <br/><br/>
            </div>
        </div>
        </TemperatureWrapper>
      </div>
    )
  }
}

const TemperatureWrapper = styled.nav `

.text{

  display: inline-block;
  padding-right: 100px;
  margin-right: 100px;
}


`