import React, { Component } from 'react'
import {SiteConsumer} from '../context'
import marked from 'marked'
import styled from 'styled-components'
import blogListArray from '../blogs/blogList'

export default class BlogPost extends Component {

  constructor(props){
    super(props)

    this.state = {
      test:'',
      markdown: '',
      blogList: blogListArray,
      fileName: '',
      fileTitle: '',
      invalidURL: false,
    }
  }

  componentDidMount() {
    //console.log("Blog Post loaded")
    
    var url = decodeURI(window.location.pathname).split( '/' )

    var fileTitle = url[url.length-1]

    this.state.blogList.map( blog => {
      if(blog.title == fileTitle){
        this.setState({
          fileName: blog.fileName,
          fileTitle: url[url.length-1],
        }, function(){ 
          fetch(require('../blogs/'+ blog.fileName))
            .then(response => response.text())
            .then(text => {
              this.setState({
                markdown: marked(text)
              }, function(){
                //console.log(this.state.markdown)
              })
            })
        })
      }
       // TODO: Handle invalid URL
    })
  }

  render() {

    return (
      <SiteConsumer>
        {(value)=>{ 

          return (
              <BlogWrapper>
                
                <div className="centerr">
                <br></br>
                  <section>
                    <article dangerouslySetInnerHTML={{__html: this.state.markdown}}></article>
                  </section>
                </div>
              </BlogWrapper>
          )
        }
      }
    </SiteConsumer> 
    )
  }
}

const BlogWrapper = styled.nav `

.centerr {

  padding-right: 10rem;
}

.tools {
  font-size:5vw;
  height: 5rem;
  display: inline-block;
  padding-bottom: 200px;
}

.fab {
  
}

.text{

}
`