import React, { Component } from 'react'
import styled from 'styled-components'

export default class Home extends Component {
  render() {
    return (
      <HomeWrapper>
      <div className="center">
      <br></br>
      
        <h2>A Brief Intro</h2>
        <div className="text">
        <br></br>
          <p>My name is Ben Thwaites and this is my demo site.</p>
          <p>Feel free to have a bit of a look about and if you're working on something interesting, don't hesitate to get in contact.</p>
          <p>Below you'll find some of the tools gone into making this site and affiliated projects.</p>
        </div>
        <div className="tools">
          <i className="fab fa-html5"></i>
          <i className="fab fa-css3-alt"></i>
          <i className="fab fa-react"></i>
          <i className="fab fa-aws"></i>
          <i className="fab fa-raspberry-pi"></i>
          <i className="fab fa-python"></i>
          <img alt="Splunk Logo" className="fab" height="70px" src={require("../images/splunk_logo.png")} />
        </div>
        <div className="text">
          <p>Also, this is still a work in progress so tread carefully.</p>
        </div>
      </div>
      </HomeWrapper>
    )
  }
}

const HomeWrapper = styled.nav `

.center {
  text-align: center;
}

.tools {
  font-size:5vw;
  height: 5rem;
  display: inline-block;
  padding-bottom: 200px;
}

.fab {
  padding-left:40px;
}

.text{

}
`