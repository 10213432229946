import React, { Component } from 'react'
import styled from 'styled-components'

export default class About extends Component {
  render() {
    return (
      <div>
        <AboutWrapper>
        <h2>About Me</h2>
        <div className="text">
          Long story short I'm a tech head. I love fiddling with anything that blinks, vibrates, computes or otherwise does something that makes me go "Arrhhhh, that's AWESOME!"
          <br/><br/>
          I started out with a a jaycar electronics kit for my 12th birthday, which got me into AVR microcontrollers, which got me into robotics which somehow got me my honours in Robotic and Mechatronic Engineering as well as a bachelor of computer science (in exchange for some hair mind you).

          <br/><br/>
          Some of my main interests include:
          <ul>
            <li>IoT (especially with the ESP8266 and ESP32)</li>
            <li>Big Data (Splunk)</li>
            <li>Raspberry Pi's</li>
            <li>React JS</li>
            <li>Beer Brewing</li>
          </ul>
          
          <h2>About This Site</h2>
          Over the years I've tinkered on a tonne of different projects but I've never really bothered to write any of it up.
          <br/>
          I've also lately started tinkering with ReactJs and was looking for a relacent project to boost my web development skills so I figured making a personal website would be kill two birds with one stone.
          <br/><br/>
          There's a bit of a backlog of things I want to add to this site but it's currently looking half reasonable with at-least one project listed so I guess this is perfect timing to share it with the world.
        </div>
        </AboutWrapper>
      </div>
    )
  }
}

const AboutWrapper = styled.nav `

.text{

  display: inline-block;
  padding-right: 100px;
  margin-right: 100px;
}

`