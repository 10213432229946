import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import {SiteConsumer} from '../context'
import styled from 'styled-components'

export default class BlogSummary extends Component {
  
  render() {
    const {title, summary, fileName, thumbNail} = this.props.BlogPost
    return (
      <BlogSummaryWrapper>
        <SiteConsumer>
          {(value)=>{ 
            return (
              <div onClick={()=>
                value.handleBlogSelection(fileName)
              }>

              <div className="card">
                <img className="card-img-top" src={thumbNail} alt="Card image cap"/>
                <div className="card-body">
                  <h5 className="card-title">{title}</h5>
                  <p className="card-text">{summary}</p>
                  <a href={"/BlogList/"+title} className="btn btn-primary">Read More</a>
                </div>
              </div>
              </div>
            )
          }
        }
        </SiteConsumer>
      </BlogSummaryWrapper>
    )
  }
}

const BlogSummaryWrapper = styled.nav `

.card {
  width: 18rem;
}

.card-img-top {
  display: block;
  width: 100%;
  height: 18rem;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
}
`
