import React, { Component } from 'react';
import {Switch, Route, Redirect} from 'react-router-dom'
import styled from 'styled-components'

import './App.css';
import "bootstrap/dist/css/bootstrap.min.css"

import Default from './components/Default'
import Home from './components/Home'
import Temperature from './components/Temperature'
import About from './components/About'
import Contact from './components/Contact'
import BlogList from './components/BlogList'
import BlogPost from './components/BlogPost'
import Z80 from './components/z80'

import Navbar from './components/Navbar'
import Footer from './components/Footer'

class App extends Component {
  render() {

    var mobile = (/iphone|android/i.test(navigator.userAgent.toLowerCase()));
    if (mobile) {
        alert("You may want to read this on a desktop");              
    }

    return (
      <React.Fragment>
        <MainWrapper>
          
          <div className="wrapper">
            <div className="menu"> 
            <br></br>
              <Navbar />
            </div>

            <div className="content"> 
            <br></br>
              <Switch>
                <Route exact path="/" render={() => (
                  <Redirect to="/Home"/>
                )}/>
                <Route exact path="/Home" component={Home} />
                <Route path="/About" component={About} />
                <Route path="/Temperature" component={Temperature} />
                <Route path="/Z80" component={Z80} />
                <Route path="/Contact" component={Contact} />
                <Route path="/BlogList/:blogTitle" component={BlogPost} />
                <Route path="/BlogList" component={BlogList} />
                
                <Route component={Default} />
              </Switch>
            </div>

            <div className="footer"> 
              <Footer />
            </div>
          </div>
        </MainWrapper>
      </React.Fragment>
    );
  }
}

export default App;

const MainWrapper = styled.nav `
  .wrapper {
    display: grid;

    grid-template-columns: 10% 90%;
    grid-template-rows: 90% fit-content;
    
  }

  .menu {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start:1 ;
    grid-row-end: 2;

    position:fixed;
    height: 100%;
    background-color: #292E34;

    width: fit-content;
    
  }

  .content {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start:1 ;
    grid-row-end: 2;
    min-height: calc(100vh - 5rem) ;
    height: auto;
    margin-left: 100px;

  }

  .footer {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start:2 ;
    grid-row-end: 3;

    bottom: 0;
    display: block;
    background-color: black;

    position: static;
  }
`
