import React, { Component } from 'react'
import blogListArray from './blogs/blogList'

const SiteContext = React.createContext()

class SiteProvider extends Component {

    componentDidMount(){
        // Is the projects item expanded?
        const ProjectsExpandedLocal = JSON.parse(localStorage.getItem('ProjectsExpanded'));

        if (typeof ProjectsExpandedLocal !== 'undefined' && ProjectsExpandedLocal !== null) {
            this.setState({
                ProjectsExpanded: !ProjectsExpandedLocal
            });
        }
        else{
            localStorage.setItem("ProjectsExpanded", "false");
            console.log("was null")
        }

        this.setState({
            blogList:blogListArray
        }) 
    }

    state = {
        ProjectsExpanded: false,
        blogList: [],
        selectedBlog: null,
    }

    toggleProjectsExpanded = () =>{

        this.setState({
            ProjectsExpanded: !this.state.ProjectsExpanded
        });
        console.log('toggled')
        localStorage.setItem("ProjectsExpanded", this.state.ProjectsExpanded);
    } 
    handleBlogSelection = (fileName) => {
        this.setState(()=>{
            return {selectedBlog:fileName}
        })
    }

    render() {
        return (
        <SiteContext.Provider value={{
            ...this.state,
            toggleProjectsExpanded:this.toggleProjectsExpanded,
            handleBlogSelection:this.handleBlogSelection,
        }}>
        {this.props.children}
        </SiteContext.Provider>
        )
    }
}

const SiteConsumer = SiteContext.Consumer

export { SiteProvider, SiteConsumer}