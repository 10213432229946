import React, { Component } from 'react'

import {SiteConsumer} from '../context'
import BlogSummary from './BlogSummary'
import BlogPost from './BlogPost'

export default class BlogList extends Component {

  render() {
    return (
        <div className="card-columns p-3">
          <div className="container">
            <SiteConsumer>
              {(value)=>{ 
                return value.blogList.map( blog =>{
                  console.log(blog)
                  return (
                      <BlogSummary key={blog.fileName} title={blog.title} BlogPost={blog}/>
                  )
                })
            }
          }
        </SiteConsumer>
        </div>
      </div>
    )
  }
}

