import React, { Component } from 'react'
import styled from 'styled-components'

export default class Z80 extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
        <div>
            <Z80Wrapper>
            <div className="Z80Wrapper">
                <h2>Z80</h2>
                    <h3>Background</h3>
                    <div className="text">
                    When I first got into Electronics at the age of 12 when my grandmother got me that first Jaycar electronics kit full of cool little buttons, LED's and resistors I became obsessed with figuring out how everything worked. 
                    I started getting the hang of things slowly but surely but one thing that was always a mystery to me were computers. They were just this black box filled with PCB's that had components so small you could hardly see.
                    I think that's when I started getting interested in older computers because they seemed somewhat understandable, not to mention had parts I could actually see myself soldering. 
                    
                
                    <br/><br/>
                    The biggest nail in the coffin that got me interested in such a useless pursuit was <a href="http://www.msarnoff.org/6809/">msarnoff</a> I could not stop drooling at his 6809 build. It was so awesome. It had a real time clock, compact flash card and a YM2149 sound chip. 
                    From all this he had made ALL HIMSELF he had asteroids, a FAT16 file system, graphics. 17 year old me was drooling.

                    I ended up also coming upon the mad wizard that is <a href="http://searle.hostei.com/grant/z80/SimpleZ80.html">Grant Searle</a> who had come up with the 'Grant's 7-chip Z80 computer' which had some great documentation. Enough for even me to be able to recreate it.
                    So after some eBaying about and pulling some hair out I had this!
                    <br/><br/>

                    <iframe className="vid" width="560" height="315" src="https://www.youtube.com/embed/ZqBO6HtG-iA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>

                    <br/><br/>

                    The sense of pride I had when I saw that little sin wave being printed down the screen. And the sense of annoyance when my dad didn't get why it was so cool. 

                    <h3>The Progress</h3>
                    <div className="text">
                    So that was cool but I wanted a more permanent solution. So I started making a prototype board solution. I also wanted something a bit more "useful" than just BASIC. Fortunately the marvellous Grant had a <a href="http://searle.hostei.com/grant/cpm/index.html">Z80 CP/M design</a> that would be cool.
                    I also wanted to add onto his work so I added a bit of extra circuitry to get memory bank switching working. It's a pretty simple little trick that allows you to have more than the standard 64kB of memory by utilising the I/O address space to control banks of memory. <a href="https://en.wikipedia.org/wiki/Bank_switching">Wikipedia</a> explains things nicely.
                    I also wanted to have the ability to have swappable IO cards for sound or graphics much like what msarnoff had done and did a couple of little projects to get the YM2149 .
                    <br/><br/>
                    Most of the circuit diagrams are scribbled in a notebook of mine. 19 year old me didn't exactly much like the formalities of engineering (in-fact 24 year old me doesn't either but at least now understand the necessity of proper documentation)

                    Here's what I got up to.

                    <br/><br/>
                    <img alt="Z80" className="images" height="500px" src={require("../images/z80.jpg")} />
                    <br/><br/>
                    <img alt="Z80-2" className="images" height="500px" src={require("../images/z80-2.jpg")} />
                    <br/><br/>

                    For the cabling I used 44AWG enamelled wire (0.05mm thick) which was slightly fiddly. I'm not sure where I heard about it but I also found this rubber strip that I believe is actually used for tennis rackets to act as a guide for the wire.
                    After a couple years of on again and off again work I finally got it to the point to test it but I needed a logic analyser. I eventually found a Chinese knock-off that I could afford but that's just sitting in my cupboard of parts.

                    <br/><br/>

                    Sadly life has gotten in the way of working on this project uni, work and getting up to speed with more pracitical and modern technology, like learning programming languages that don't require you to look up hex codes.

                    <br/><br/>
                    <h3>Next Steps</h3>
                    
                    I'd love to finish this project off, at least to the point of it running BASIC or CP/M to realize my younger selves goal. So hopefully in the next coming weekends I'll get the logic analyser and my old diagrams and see what I can do about getting this beast going.

                    <br/><br/>
                    <br/><br/>
                    </div>
            </div>
            </Z80Wrapper>
        </div>
    )
  }
}

const Z80Wrapper = styled.nav `

.text{

  display: inline-block;
  padding-right: 100px;
  margin-right: 100px;
}

.vid {
    display: block;
    margin: 0 auto;
 }

 .images {
    display: block;
    margin: 0 auto;   
 }


`